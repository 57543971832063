import { createRouter, createWebHistory } from 'vue-router'
// import Home from './views/Home.vue'
import store from "../store"
import _const from "../store/const";

const beforeAuth = isAuth => async (from, to, next) => {
  let isAuthenticated = await isLoggedIn();

  // console.log(isAuthenticated)
  if (!isAuth || (isAuthenticated && isAuth)) {
    return next();
  } else {
    // console.log('로그인으로 이동');
    // 홈 화면으로 이동
    return next({
      path: "/login",
      query: { redirect: from.fullPath }
    });
  }
}

const isLoggedIn = async () => {
  let isAuthenticated = store.state.auth.isLogin
  let token = localStorage.getItem(_const.TOKEN);
  if (token && !store.state.auth.isInit) {
    await store.dispatch('init');
  }

  if (!isAuthenticated) {
    if (token) {
      isAuthenticated = store.state.auth.isLogin;
      // console.log(store.state.auth.isLogin)
    }
  }

  return isAuthenticated;
}

const routes = [
  {
    path: '/:pathMatch(.*)*',
    redirect: "/error/404"
  },
  {
    path: "/error/404",
    name: "notFound",
    component: () => import(/* webpackChunkName: "NotFound" */ './views/NotFound.vue'),
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackPrefetch: true */ './views/Home.vue'),
    beforeEnter: beforeAuth(false),
    meta: { page: 'main', header: 'grey' }
  },
  {
    path: '/terms/damage',
    name: 'Damage',
    component: () => import(/* webpackChunkName: "Damage" */ './views/terms/Damage.vue'),
    meta: { page: 'terms', title: '피해보상처리규정', doc_title: "피해보상처리규정" }
  },
  {
    path: '/terms/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "Privacy" */ './views/terms/Privacy.vue'),
    meta: { page: 'terms', title: '개인정보처리방침', doc_title: "개인정보처리방침" }
  },
  {
    path: '/terms/use',
    name: 'Use',
    component: () => import(/* webpackChunkName: "Use" */ './views/terms/Use.vue'),
    meta: { page: 'terms', title: '사이트 이용약관', doc_title: "사이트 이용약관" }
  },
  {
    path: '/terms/user',
    name: 'User',
    component: () => import(/* webpackChunkName: "User" */ './views/terms/User.vue'),
    meta: { page: 'terms', title: '회원약관', doc_title: "회원약관" }
  },
  {
    path: '/terms/storage',
    name: 'Storage',
    component: () => import(/* webpackChunkName: "Storage" */ './views/terms/Storage.vue'),
    meta: { page: 'terms', title: '보관 이용규정', doc_title: "보관 이용규정" }
  },
  {
    path: '/terms/move',
    name: 'Move',
    component: () => import(/* webpackChunkName: "Storage" */ './views/terms/Move.vue'),
    meta: { page: 'terms', title: '운송 이용규정', doc_title: "운송 이용규정" }
  },
  {
    path: '/users/id/find',
    name: 'FindID',
    component: () => import(/* webpackChunkName: "FindID" */ './views/FindID.vue'),
    meta: { page: 'find-id', title: "아이디 찾기", doc_title: "아이디 찾기" }
  },
  {
    path: '/users/id/find/result',
    name: 'FindIDResult',
    component: () => import(/* webpackChunkName: "FindID" */ './views/FindIDResult.vue'),
    meta: { page: 'find-id', title: "아이디 찾기 결과", doc_title: "아이디 찾기 결과" }
  },
  {
    path: '/users/passwd/reset',
    name: 'ResetPasswd',
    component: () => import(/* webpackChunkName: "ResetPasswd" */ './views/ResetPasswd.vue'),
    meta: { page: 'reset-passwd', title: "비밀번호 재설정", doc_title: "비밀번호 재설정" }
  },
  {
    path: '/login',
    name: 'Login',
    beforeEnter: async (from, to, next) => {
      let isAuthenticated = await isLoggedIn();
      // console.log(isAuthenticated)
      if (isAuthenticated) {
        next('/');
      } else {
        next();
      }
    },
    component: () => import(/* webpackChunkName: "Login" */ './views/Login.vue'),
    meta: { page: 'login', doc_title: '로그인' }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "Logout" */ './views/Logout.vue'),
    meta: { page: 'logout' }
  },
  {
    path: '/login/naver/callback',
    name: 'LoginNaverCallback',
    component: () => import(/* webpackChunkName: "LoginNaverCallback" */ './views/login/LoginNaverCallback.vue'),
    meta: { page: 'login' }
  },
  {
    path: '/login/kakao/callback',
    name: 'LoginKakaoCallback',
    component: () => import(/* webpackChunkName: "LoginKakaoCallback" */ './views/login/LoginKakaoCallback.vue'),
    meta: { page: 'login' }
  },
  {
    path: '/login/google/callback',
    name: 'LoginGoogleCallback',
    component: () => import(/* webpackChunkName: "LoginKakaoCallback" */ './views/login/LoginGoogleCallback.vue'),
    meta: { page: 'login' }
  },
  {
    path: '/login/apple/callback',
    name: 'LoginAppleCallback',
    component: () => import(/* webpackChunkName: "LoginKakaoCallback" */ './views/login/LoginAppleCallback.vue'),
    meta: { page: 'login' }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "Signup" */ './views/Signup.vue'),
    meta: { page: 'signup', type: 'ID', title: '회원가입', doc_title: '회원가입' }
  },
  {
    path: '/signup/:type/certification',
    name: 'SignupCertification',
    component: () => import(/* webpackChunkName: "SignupCertification" */ './views/SignupCertification.vue'),
    meta: { page: 'signup', title: '회원가입 본인인증' }
  },
  {
    path: '/welcome',
    name: 'SignupComplete',
    component: () => import(/* webpackChunkName: "SignupComplete" */ './views/SignupComplete.vue'),
    meta: { page: 'signup-complete', type: 'ID', title: '회원가입완료', doc_title: '회원가입완료' }
  },
  {
    path: '/signup/certification/result',
    name: 'SignupResult',
    component: () => import(/* webpackChunkName: "SignupResult" */ './views/SignupResult.vue'),
    meta: { page: 'signup-result', type: 'ID', title: '회원가입 본인인증결과' }
  },
  {
    path: '/signup/:sns',
    name: 'SignupSNS',
    component: () => import(/* webpackChunkName: "Signup" */ './views/Signup.vue'),
    meta: { page: 'signup', type: 'SNS', doc_title: '회원가입' }
  },
  {
    path: '/brand',
    name: 'Brand',
    component: () => import(/* webpackChunkName: "Brand" */ './views/Brand.vue'),
    meta: { page: 'brand', title: '브랜드소개', doc_title: '브랜드소개' }
  },
  {
    path: '/guide',
    name: 'GuideTemp',
    component: () => import(/* webpackChunkName: "GuideEstimate" */ './views/guide/GuideTemp.vue'),
    meta: { page: 'guide', title: '사용가이드', doc_title: '사용가이드', doc_desc: '밥아저씨 사용법을 자세하게' }
  },
  {
    path: '/guide/estimate',
    name: 'GuideEstimate',
    beforeEnter: beforeAuth(false),
    component: () => import(/* webpackChunkName: "GuideEstimate" */ './views/guide/GuideEstimate.vue'),
    meta: { page: 'guide', title: '운송 사용가이드', doc_title: '운송 사용가이드', menu: 'guide' }
  },
  {
    path: '/guide/storage',
    name: 'GuideStorage',
    beforeEnter: beforeAuth(false),
    component: () => import(/* webpackChunkName: "GuideStorage" */ './views/guide/GuideStorage.vue'),
    meta: { page: 'guide', title: '보관 사용가이드', doc_title: '보관 사용가이드', menu: 'guide' }
  },
  // {
  //   path: '/estimate/simple',
  //   name: 'SimpleEstimate',
  //   component: () => import(/* webpackPrefetch: true */ './views/estimate/SimpleEstimate.vue'),
  //   beforeEnter: beforeAuth(false),
  //   meta: { page: 'estimate', doc_title: '간편견적', doc_desc: '회원가입이 필요 없는 간편 견적' }
  // },
  // {
  //   path: '/estimate',
  //   name: 'EstimateTemp',
  //   component: () => import(/* webpackPrefetch: true */ './views/estimate/EstimateTemp.vue'),
  //   beforeEnter: beforeAuth(false),
  //   meta: { page: 'estimate', title: '상세견적', doc_title: '상세견적', doc_desc: '견적부터 결제 주문까지 한 번에' }
  // },
  // {
  //   path: '/estimate/move',
  //   name: 'Estimate',
  //   component: () => import(/* webpackPrefetch: true */ './views/Estimate.vue'),
  //   beforeEnter: beforeAuth(true),
  //   meta: { page: 'estimate', title: '운송 상세 견적보기', doc_title: '견적보기', menu: 'estimate' }
  // },
  {
    path: '/estimate/payment',
    name: 'EstimatePayment',
    beforeEnter: beforeAuth(true),
    component: () => import(/* webpackChunkName: "EstimatePayment" */ './views/EstimatePayment.vue'),
    meta: { page: 'estimate', title: '운송 상세 견적보기' }
  },
  {
    path: '/estimate/payment/complete/mobile',
    name: 'EstimatePaymentCompleteMobile',
    // beforeEnter: beforeAuth(true),
    component: () => import(/* webpackChunkName: "EstimatePaymentCompleteMobile" */ './views/EstimatePaymentCompleteMobile.vue'),
    meta: { page: 'estimate', title: '결제결과' }
  },
  {
    path: '/storage/payment/complete/mobile',
    name: 'StoragePaymentCompleteMobile',
    // beforeEnter: beforeAuth(true),
    component: () => import(/* webpackChunkName: "EstimatePaymentCompleteMobile" */ './views/StoragePaymentCompleteMobile.vue'),
    meta: { page: 'estimate', title: '결제결과' }
  },
  {
    path: '/storage-delivery/payment-complete/mobile',
    name: 'StorageDeliveryPaymentCompleteMobile',
    // beforeEnter: beforeAuth(true),
    component: () => import(/* webpackChunkName: "EstimatePaymentCompleteMobile" */ './views/StorageDeliveryPaymentCompleteMobile.vue'),
    meta: { page: 'estimate', title: '결제결과' }
  },
  {
    path: '/my/payment/complete/mobile',
    name: 'MyPaymentCompleteMobile',
    // beforeEnter: beforeAuth(true),
    component: () => import(/* webpackChunkName: "EstimatePaymentCompleteMobile" */ './views/MyPaymentCompleteMobile.vue'),
    meta: { page: 'my', title: '결제결과' }
  },
  // {
  //   path: '/estimate/storage',
  //   name: 'StorageEstimate',
  //   component: () => import(/* webpackPrefetch: true */ './views/StorageEstimate.vue'),
  //   beforeEnter: beforeAuth(true),
  //   meta: { page: 'estimate', title: '보관 상세 견적보기', doc_title: '견적보기', contractType: 'initial', menu: 'estimate' }
  // },
  // {
  //   path: '/estimate/all',
  //   name: 'AllEstimate',
  //   component: () => import(/* webpackChunkName: "StorageEstimate" */ './views/AllEstimate.vue'),
  //   beforeEnter: beforeAuth(true),
  //   meta: { page: 'estimate', title: '이사+보관 상세 견적보기', doc_title: '견적보기', contractType: 'initial' }
  // },
  // {
  //   path: '/estimate/storage/extension',
  //   name: 'StorageEstimateExtension',
  //   component: () => import(/* webpackPrefetch: true */ './views/StorageEstimate.vue'),
  //   beforeEnter: beforeAuth(true),
  //   meta: { page: 'estimate', title: '보관 연장하기', doc_title: '박스연장 견적보기', contractType: 'extension' }
  // },
  // {
  //   path: '/estimate/storage/delivery',
  //   name: 'StorageEstimateDelivery',
  //   component: () => import(/* webpackPrefetch: true */ './views/StorageEstimate.vue'),
  //   beforeEnter: beforeAuth(true),
  //   meta: { page: 'estimate', title: '박스 배송신청', doc_title: '박스 중간배송신청', contractType: 'delivery' }
  // },
  // {
  //   path: '/estimate/storage/final/delivery',
  //   name: 'StorageEstimateFinalDelivery',
  //   component: () => import(/* webpackPrefetch: true */ './views/StorageEstimate.vue'),
  //   beforeEnter: beforeAuth(true),
  //   meta: { page: 'estimate', title: '종료 후 배송하기', doc_title: '종료 후 배송하기', contractType: 'final', finalType: 'delivery' }
  // },
  // {
  //   path: '/estimate/storage/final/dispose',
  //   name: 'StorageEstimateFinalDispose',
  //   component: () => import(/* webpackPrefetch: true */ './views/StorageEstimate.vue'),
  //   beforeEnter: beforeAuth(true),
  //   meta: { page: 'estimate', title: '종료 후 폐기신청', doc_title: '종료 후 폐기신청', contractType: 'final', finalType: 'dispose' }
  // },
  {
    path: '/my/order/storage/boxes',
    name: 'MyStorageBoxes',
    component: () => import(/* webpackChunkName: "MyStorageBox" */ './views/mypage/MyStorageBox.vue'),
    beforeEnter: beforeAuth(true),
    meta: { page: 'my', title: '마이페이지', subTitle: '내보관관리', doc_title: '내 보관목록' }
  },
  // {
  //   path: '/my/order/storage/boxes/:serial',
  //   name: 'MyStorageBoxDetail',
  //   component: () => import(/* webpackChunkName: "MyMoveOrderDetail" */ './views/mypage/MyStorageBoxDetail.vue'),
  //   beforeEnter: beforeAuth(true),
  //   meta: { page: 'my', title: '내박스관리', doc_title: '내 보관박스' }
  // },
  {
    path: '/my',
    name: 'MyPage',
    component: () => import(/* webpackChunkName: "MyPageMain" */ './views/mypage/MyPageMain.vue'),
    beforeEnter: beforeAuth(true),
    meta: { page: 'my', title: '마이페이지', doc_title: '마이페이지' }
  },
  {
    path: '/my/company',
    name: 'MyCompany',
    component: () => import(/* webpackChunkName: "MyCompany" */ './views/mypage/MyCompany.vue'),
    beforeEnter: beforeAuth(true),
    meta: { page: 'my', title: '마이페이지', doc_title: '기업전용' }
  },
  {
    path: '/my/company/payment-complete/mobile',
    name: 'MyCompanyPaymentCompleteMobile',
    component: () => import(/* webpackChunkName: "MyCompanyPaymentCompleteMobile" */ './views/mypage/MyCompanyPaymentCompleteMobile.vue'),
    // beforeEnter: beforeAuth(true),
    meta: { page: 'my', title: '마이페이지', doc_title: '기업전용' }
  },
  {
    path: '/my/payment',
    name: 'MyPayment',
    component: () => import(/* webpackChunkName: "MyPayment" */ './views/mypage/MyPayment.vue'),
    beforeEnter: beforeAuth(true),
    meta: { page: 'my', title: '마이페이지', doc_title: '내결제수단' }
  },
  {
    path: '/my/order/storage/:serial',
    name: 'MyStorageOrder',
    component: () => import(/* webpackChunkName: "MyStorageOrder" */ './views/mypage/MyStorageOrder.vue'),
    beforeEnter: beforeAuth(true),
    meta: { page: 'my', title: '마이페이지', doc_title: '주문상세' }
  },
  // {
  //   path: '/my/order/move',
  //   name: 'MyMoveOrders',
  //   component: () => import(/* webpackChunkName: "MyMoveOrder" */ './views/mypage/MyMoveOrder.vue'),
  //   beforeEnter: beforeAuth(true),
  //   meta: { page: 'my', title: '마이페이지', subTitle: '내운송관리', doc_title: '내 배송목록' }
  // },
  // {
  //   path: '/my/order/move/:serial',
  //   name: 'MyMoveOrderDetail',
  //   component: () => import(/* webpackChunkName: "MyMoveOrderDetail" */ './views/mypage/MyMoveOrderDetail.vue'),
  //   beforeEnter: beforeAuth(true),
  //   meta: { page: 'my', title: '내운송관리', doc_title: '내 배송상태' }
  // },
  {
    path: '/my/profile',
    name: 'MyProfile',
    component: () => import(/* webpackChunkName: "MyProfile" */ './views/mypage/MyProfile.vue'),
    beforeEnter: beforeAuth(true),
    meta: { page: 'my', title: '마이페이지', subTitle: '프로필관리', doc_title: '내 프로필' }
  },
  {
    path: '/my/profile/certification/result',
    name: 'ProfileCertificationResult',
    component: () => import(/* webpackChunkName: "ProfileCertificationResult" */ './views/ProfileCertificationResult.vue'),
    meta: { page: 'my', title: '프로필관리' }
  },
  {
    path: '/my/passwd/change',
    name: 'MyPasswdChange',
    component: () => import(/* webpackChunkName: "MyPasswdChange" */ './views/MyPasswdChange.vue'),
    beforeEnter: beforeAuth(true),
    meta: { page: 'my', title: '마이페이지', subTitle: '비밀번호변경', doc_title: '비밀번호변경' }
  },
  {
    path: '/support/main',
    name: 'SupportMain',
    beforeEnter: beforeAuth(false),
    component: () => import(/* webpackChunkName: "SupportMain" */ './views/SupportMain.vue'),
    meta: { page: 'support', title: '고객센터', subTitle: "상담안내", doc_title: '고객센터', doc_desc: '밥아저씨에게 연락하기' }
  },
  {
    path: '/support/faq',
    name: 'SupportFAQ',
    beforeEnter: beforeAuth(false),
    component: () => import(/* webpackChunkName: "SupportFAQ" */ './views/SupportFAQ.vue'),
    meta: { page: 'support', title: '고객센터', subTitle: 'FAQ', doc_title: 'FAQ' }
  },
  {
    path: '/payments/custom/:url',
    name: 'CustomPayment',
    component: () => import(/* webpackChunkName: "CustomPayment" */ './views/CustomPayment.vue'),
    meta: { page: 'payment', title: '추가 결제', doc_title: '추가결제' }
  },
  {
    path: '/callback/payments/custom/mobile',
    name: 'CustomPaymentCompleteMobile',
    // beforeEnter: beforeAuth(true),
    component: () => import(/* webpackChunkName: "CustomPaymentCompleteMobile" */ './views/CustomPaymentCompleteMobile.vue'),
    meta: { page: 'payment', title: '결제 결과' }
  },
  {
    path: '/boxes/:serial/content',
    name: 'NewBoxContent',
    beforeEnter: async (from, to, next) => {
      await isLoggedIn();
      next();
    },
    component: () => import(/* webpackChunkName: "CustomPayment" */ './views/NewBoxContent.vue'),
    meta: { page: 'box-content', title: '박스 내용입력', doc_title: '박스 내용 입력하기' }
  },
  {
    path: '/event-free-month3',
    name: 'FreeEventStorageBoxEstimate',
    component: () => import(/* webpackPrefetch: true */ './views/Home.vue'),
    beforeEnter: beforeAuth(true),
    meta: { page: 'main', header: 'grey', enter_type: 'event3' }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from){
    if (to.path !== from.path) {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.doc_title) {
    document.title = `${to.meta.doc_title}`;

    if (to.meta.doc_desc) {
      document.description = `${to.meta.doc_desc}`;
    }
  } else {
    document.title = 'BOB';
  }
  next();
});


// router.afterEach((to, from) => {
//   const title = to.meta.title === undefined ? 'BOB' : to.meta.title;
//   Vue.nextTick(() => {
//     document.title = title;
//   });
// });

// router.beforeEach( async(to, from, next) => {
//
//   if(localStorage.getItem('token')===null && localStorage.getItem('refresh_token') !== null){
//     // await refreshToken();
//   }
//
//   console.log(to)
//   if (to.matched.some(record => record.meta.unauthorized) || localStorage.getItem('token')){
//     return next();
//   }
//
//   alert('로그인 해주세요');
//   return next('/login');
// })

export default router
