<style lang="scss">
@import './assets/scss/common/variables.scss';
@import './assets/scss/common/mixin.scss';
@import './assets/scss/common/normalize.css';

html {
  font-size: 16px;
  min-height: 100%;
  scroll-behavior: smooth;

  //
  //@include respond(desktop) {
  //  min-width: 1400px;
  //}
  //
  @include respond(tablet) {
    font-size: 14px;
  }
  @include respond(mobile) {
    font-size: 12px;
  }
}

.main-content {
  margin-top: $header-height;

  @include respond(mobile) {
    margin-top: $header-m-height;
  }
}

.main-panel.ios-app {
  .main-content.my {
    > .container {
      padding-top: 90px;
    }
  }
}

body.fit-page {
  &:not(.no-center) {
    .main-panel .main-content {
      @include vertical-align-center();
    }
  }

  &.home {
    .main-panel {
      @include respond(desktop) {
        .main-content {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .main-panel {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .main-content {
      flex: auto;
    }

    @include respond(mobile) {
      min-height: -webkit-fill-available;

      .main-content {
        //min-height: 550px;
        min-height: calc(
          calc(var(--vh, 1vh) * 100) - $header-m-height
        ) !important;
      }
    }

    @include respond(only-tablet) {
      .main-content {
        //min-height: 550px;
        min-height: calc(
          calc(var(--vh, 1vh) * 100) - $header-height
        ) !important;
      }
    }

    @include respond(desktop) {
      .main-content {
        min-height: 750px !important;
      }
    }
  }
}

@import './assets/scss/common/bootstrap-grid.min.css';
@import './assets/scss/common/xeicon.min.css';
@import './assets/scss/common/space.scss';
@import './assets/scss/common/base.scss';
@import './assets/scss/common/table.scss';
@import './assets/scss/common/input.scss';
@import './assets/scss/common/button.scss';

//@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:800');

body.scroll-lock {
  overflow: hidden;
  touch-action: none;
}

//
//* {
//  -webkit-font-smoothing: subpixel-antialiased !important;
//  text-rendering:optimizeLegibility !important;
//}

@font-face {
  font-family: NotoSansKR;
  src: url('assets/fonts/NotoSansKR-DemiLight.otf');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: NotoSansKR;
  src: url('assets/fonts/NotoSansKR-Regular.otf');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: NotoSansKR;
  src: url('assets/fonts/NotoSansKR-Medium.otf');
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: NotoSansKR;
  src: url('assets/fonts/NotoSansKR-Bold.otf');
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: 'SBAggroL';
  src: url('assets/fonts/SBAggroL.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SBAggroM';
  src: url('assets/fonts/SBAggroM.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  //color: #2c2c2c;
  font-size: 16px;
  font-family: 'NotoSansKR', 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  overflow-x: hidden;
}

.main-content > .container {
  padding-top: 20px;
  padding-bottom: 20px;

  //@include respond(mobile) {
  //  padding-bottom: 40px;
  //}
}

.main-content.main > .container {
  padding-top: 0px;
  padding-bottom: 20px;
}

.ios-app {
  .main-content {
    padding-bottom: 77px !important;
    margin-top: 0 !important;
  }

  .c-toast-container--bottom {
    padding-bottom: 80px !important;
    padding-right: 10px;
  }

  .c-toast-container--top {
    padding-top: 60px !important;
    padding-right: 10px;
  }
}

//.main-content {
//  @include respond(mobile) {
//    padding-bottom: 50px;
//  }
//}
</style>

<template>
  <NetworkAlert ref="alert" @exit="exitApp" @retry="retryConnection"/>
  <div id="initLoading" style="width: 100vw; height: 100vh; background-color: #fff; z-index: 9999; position: fixed; top: 0; left: 0;"></div>
  <div id="mainContainer" class="main-panel" :class="[{ 'ios-app': isApp }, $route.name]" style="min-height: 100vh;">
    <Header v-if="!isApp" v-bind="$router.props" />
    <!-- <div class="main-content" :class="$route.meta.page" style="min-height: 100vh;"> -->
    <div class="main-content" :class="$route.meta.page">
      <router-view />
    </div>
    <Footer v-if="!isApp && !$route.meta.isHideFooter" />
    <BottomMenu v-if="isApp" />
    <!-- BottomMenu 컴포넌트 추가 -->
  </div>
</template>

<script>
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import BottomMenu from './components/layout/BottomMenu';
import { Network } from '@capacitor/network';
import { App as CapacitorApp } from '@capacitor/app'
import NetworkAlert from './components/NetworkAlert.vue';

export default {
  components: { Footer, Header, BottomMenu, NetworkAlert },
  data() {
    return {
      isApp: false,
      isIosApp: false,
    };
  },
  mounted() {
    this.checkIfApp();

    // android에서만 동작
    if(this.isApp && !this.isIosApp){
      Network.addListener('networkStatusChange', this.handleNetworkChange);
      this.checkNetworkStatus();
    }
    if (!window.loader) {
      window.loader = null;
      window.showLoader = (config) => {
        if (window.loader) return config;
        window.loader = this.$loading.show();
        return config;
      };

      window.hideLoader = () => {
        window.loader && window.loader.hide();
        window.loader = null;
      };
    }

    document.getElementById('initLoading').style.display = 'none';
  },
  beforeRouteEnter(to, from, next) {
    next(() => {
      const loadingElement = document.getElementById('initLoading');
      if (loadingElement) {
        loadingElement.style.display = 'none';
      }
    });
  },
  // methods: {
  //   ...mapActions(['init']),
  // },
  beforeCreate() {
    // const store = useStore();
    // this.init(store);
    // this.init();
    // this.$store.dispatch('init');
  },
  methods: {
    checkIfApp() {
      // 하단메뉴 ios용
      this.isIosApp = window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.iosAppHandler;window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.iosAppHandler;

      // console.log('main app: '+this.isIosApp);
      if (this.isIosApp) {
        this.isApp = this.isIosApp;
      } else {
        this.isApp = this.$store.getters['common/isAppMenuShow'];
      }
      // this.isApp = true;
    },
    async checkNetworkStatus() {
      const status = await Network.getStatus();
      this.handleNetworkChange(status);
    },
    handleNetworkChange(status) {
      if (!status.connected) {
        this.$refs.alert.show();
      }else{
        this.$refs.alert.hide()
      }
    },
    exitApp() {
      CapacitorApp.exitApp();
    },
    retryConnection() {
      this.checkNetworkStatus();
    }
  },
};
</script>
