import _api  from './api';
import _endpoints from './endpoints';
import _const from '../store/const';
import { Capacitor } from '@capacitor/core';

function setHeaders(accessToken, refreshToken) {
  localStorage.setItem(_const.TOKEN, accessToken);
  _api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

  if (refreshToken) {
    localStorage.setItem(_const.REFRESH_TOKEN, refreshToken);
    _api.defaults.headers.common['refresh-token'] = `${refreshToken}`;
  }
}

export default {
  async login(type, data) {
    let res = await _api.post(_endpoints.login.replace(':type', type), data);

    // console.log(res);

    if (res && res.data && res.data['access_token']) {
      setHeaders(res.data['access_token'], res.data['refresh_token']);
    }

    return res;
  },

  async loginForNaver(code, state) {
    let { data } = await _api.post(_endpoints.login_for_naver, {
      code,
      state,
    });

    if (data && data['access_token']) {
      setHeaders(data['access_token'], data['refresh_token']);
    }

    return data;
  },

  async loginForSnsByCode(type, code, token, name, snsId) {
    let endpoint;
    let obj = {
      code,
    };

    if (type === 'kakao') endpoint = _endpoints.login_for_kakao;
    else if (type === 'google') {
      endpoint = _endpoints.login_for_google;
      obj['is_app'] = Capacitor.isNativePlatform()
    }
    else if (type === 'apple') {
      endpoint = _endpoints.login_for_apple;
      if (token) obj['id_token'] = token;
      if (name) obj['name'] = name;
      if (snsId) obj['sns_id'] = snsId;
    }

    let { data } = await _api.post(endpoint, obj);

    if (data && data['access_token']) {
      setHeaders(data['access_token'], data['refresh_token']);
    }

    return data;
  },

  async loginForSns(type, snsId) {
    let { data } = await _api.post(
      _endpoints.login_for_sns.replace(':type', type),
      {
        username: snsId,
      }
    );

    if (data && data['access_token']) {
      setHeaders(data['access_token'], data['refresh_token']);
    }

    return data;
  },

  async logout() {
    let res;
    try {
      res = await _api.post(_endpoints.logout);
      // console.log(res);
    } catch (err) {
      console.log(err);
    }

    debugger;
    return res;
  },

  async checkPasswd(passwd) {
    return await _api.get(_endpoints.check_passwd, {
      params: { str: passwd },
    });
  },

  async getCertifications(uid) {
    let { data } = await _api.post(_endpoints.get_certifications, {
      imp_uid: uid,
    });

    return data;
  },

  async getIdByCertifications(uid) {
    let { data } = await _api.get(
      _endpoints.get_id_by_certifications.replace(':uid', uid)
    );

    return data;
  },
};
