<template>
  <footer class="footer" id="mainFooter">
    <div class="container">
      <div class="container-fluid">
        <div>
          <ul>
            <li>
              <router-link to="/terms/use" @click="$helper.sendGA('click_btn_footer', { 'target': '이용약관' })">이용약관</router-link>
            </li>
            <li>
              <router-link to="/terms/privacy" @click="$helper.sendGA('click_btn_footer', { 'target': '개인정보처리방침' })">개인정보처리방침</router-link>
            </li>
<!--            <li>-->
<!--              <router-link to="/terms/damage" @click="$helper.sendGA('click_btn_footer', { 'target': '피해보상규정약관' })">피해보상규정약관</router-link>-->
<!--            </li>-->
<!--            <br class="mobile-show">-->
            <li>
              <router-link to="/terms/storage" @click="$helper.sendGA('click_btn_footer', { 'target': '보관이용규정' })">보관이용규정</router-link>
            </li>
<!--            <li>-->
<!--              <router-link to="/terms/move" @click="$helper.sendGA('click_btn_footer', { 'target': '운송이용규정' })">운송이용규정</router-link>-->
<!--            </li>-->
<!--            <li>-->
<!--              <router-link to="/terms/user">회원약관</router-link>-->
<!--            </li>-->
          </ul>
        </div>

        <div class="logo-row">
          <div class="logo-wrap">
            <img class="img-logo" src="~/assets/img/logo_bob_grey.png">
          </div>

          <div class="corp-wrap">
            <div class="corp">
              <!--            <div class="mb-3">-->
              <!--              <img class="img-logo" src="~/assets/img/logo_via_white.svg">-->
              <!--            </div>-->
              주식회사 비아컴퍼니
            </div>
            <div class="corp"><span><strong class="bolder">사업자주소</strong> 대구광역시 달성군 유가읍 현풍로 47길 86-2</span> <span><strong class="bolder">전화번호 </strong> 070-7712-2212</span> <span><strong class="bolder">사업자번호</strong> 761-86-02581</span> <span><strong class="bolder">통신판매업</strong> 2022-대구달성-0888</span> <span><strong class="bolder">대표자</strong> 김지훈, 정철승</span></div>
            <!--        <div class="corp"><strong>사업자번호</strong> 761-86-02581</div>-->
            <!--        <div class="corp"><strong>통신판매업</strong> 2022-대구달성-0888</div>-->
            <!--        <div class="corp"><strong>대표자</strong> 김지훈, 정철승</div>-->
            <div class="mobile-show corp-br"></div>
            <div class="corp">비아컴퍼니에서 운영하는 사이트에서 판매되는 모든 상품은 <br class="mobile-show">비아컴퍼니에서책임지고 있습니다.</div>
            <div class="corp">민원담당자 정철승 / 070-7712-2212</div>
          </div>
        </div>

      </div>

<!--      <button class="btn btn-icn btn-top" @click="scrollToTop">-->
<!--        <i class="xi-arrow-up"></i>-->
<!--      </button>-->
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    scrollToTop() {
      window.scrollTo(0,1);
    }
  }
}
</script>

<style scoped lang="scss">
body:not(.grey-bg) .footer {
  border-top: 1px solid #D0D4DC;
}
.footer {
  position: relative;
  padding: 40px 0 50px;
  color: #ccc;
  background-color: #fff;
  text-align: left;

  .container {
    position: relative;
  }

  .logo-row {
    display: flex;

    > .logo-wrap {
      width: 90px;
    }
    > .corp-wrap {
      flex: 1;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0 0 25px;
    //color: #000;

    li {
      display: inline-block;

      a {
        color: #000;
        text-decoration: none;
      }

      &:nth-of-type(n+2) {
        padding-left: 30px;
      }

      &:nth-of-type(2) a {
        font-weight: bold;
      }
    }
  }

  .brand {
    font-family: "montserrat", sans-serif;
    font-weight: bold;
    font-size: 23px;
    padding-top: 40px;
    padding-bottom: 20px;
    color: #747474;
  }

  .corp {
    font-size: 15px;
    line-height: 1.7;
    color: #AAAEB6;

    span {
      white-space: nowrap;
    }
  }

  .btn-top {
    position: absolute;
    display: inline-block;
    width: 70px;
    height: 70px;
    top: -75px;
    right: 0.75rem;
    background-color: #000;
    @include border-round(100%);
    color: #fff;
    font-size: 30px;
    padding: 0;

    @include respond(not-mobile) {
      transition: background-color 0.3s ease-in-out;
      &:hover {
        background-color: $sub-color2;
      }
    }
  }

  .img-logo {
    height: 25px;
  }

  .corp-br {
    height: 10px;
  }

  @include respond(mobile) {
    padding: 20px 0 30px;
    //text-align: center;

    ul {
      line-height: 30px;
      padding-bottom: 20px;
      font-size: 11px;

      li:nth-of-type(n+2) {
        padding-left: 20px;
      }

      li:nth-of-type(4) {
        padding-left: 0;
      }
    }

    .brand {
      font-size: 20px;
      padding-bottom: 5px;
    }
    .corp {
      font-size: 10px;
      line-height: 1.75;
    }

    .btn-top {
      width: 45px;
      height: 45px;
      font-size: 27px;
      top: -22px;
      right: 15px;
    }

    .logo-row {
      > .logo-wrap {
        width: 70px;
      }
    }

    .img-logo {
      height: 18px;
    }
  }

  @include respond(xs-mobile) {
    .logo-row {
      flex-direction: column;

      .corp-wrap {
        padding-top: 10px;
      }
    }
  }
  //
  //.img-logo {
  //  //width: 270px;
  //  //opacity: 0.3;
  //  height: 25px;
  //
  //  + .img-logo {
  //    margin-left: 20px;
  //    height: 30px;
  //  }
  //}
}

</style>
