import axios from "axios";
import _const from '../store/const';

var baseUrl = "http://127.0.0.1:8000";

if (window.location.host === "dev.mrbob.kr") {
    baseUrl = "https://dev-api.mrbob.kr";
} else if (window.location.host === "mrbob.kr" || window.location.host === "stg.mrbob.kr") {
    baseUrl = "https://api.mrbob.kr";
}

var api = axios.create({
    baseURL: baseUrl,
    headers: {
        "Content-type": "application/json"
    }
});

let token = localStorage.getItem(_const.TOKEN);
let refreshToken = localStorage.getItem(_const.REFRESH_TOKEN);

if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    if (refreshToken) {
        api.defaults.headers.common['refresh-token'] = `${refreshToken}`;
    }
}
//
// api.interceptors.request.use(async function (config) {
//     // Do something before request is sent
//     config.headers.token = VueCookies.get('token');
//     config.headers.refresh_token = VueCookies.get('refresh_token');
//
//     console.log(config);
//     return config;
// }, function (error) {
//     // Do something with request error
//     return Promise.reject(error);
// });

api.interceptors.request.use(
  (config) => {
    // console.log('Request Config:', config);
    window.showLoader(config);
    return config;
  },
  (error) => {
    console.error('Request Error:', error);
    return Promise.reject(error);
  }
);

async function refresh(originalAPI) {
  try {
    const { data } = await api.post('/api/token/refresh');

    api.defaults.headers.common['Authorization'] = `Bearer ${data['access_token']}`;
    originalAPI.headers['Authorization'] = `Bearer ${data['access_token']}`;
    localStorage.setItem(_const.TOKEN, data['access_token']);
  } catch (e) {
    window.location.href = '/logout?expired';
  }
}

// Add a response interceptor
api.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    window.hideLoader();
    return response;
}, async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    window.hideLoader();
    // console.log('에러', error.stringify());
    // console.log('에러', JSON.stringify(error.config));
    // console.log('에러', JSON.stringify(error.response));
    const errorAPI = error.config;
    console.error(error);

    if (error.response && error.response.status===401){
        if (error.response.data) {
            if (error.response.data.code === 'expired_token') {
                if (errorAPI.retry) {
                    // console.log('api - expired_token - retry: logout');
                    window.location.href = '/logout';
                } else {
                    errorAPI.retry = true;
                    // console.log('api - refresh token try');
                    await refresh(errorAPI);
                    return await axios(errorAPI);
                }
            } else if (error.response.data.code === 'invalid_token') {
                // console.log('api - invalid_refresh: logout');
                window.location.href = '/logout?expired';
            }
        } else {
            // console.log(error.response)
            // console.log('api - 401 not expired_token: logout');
            window.location.href = '/logout';
        }

        // alert(error.response.data.message);
    }

    return Promise.reject(error);
});

export default api;
