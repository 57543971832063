<template>
  <div class="bottom-nv">
    <div class="layout">
      <div :class="['tab-1', isActive('/brand')]" @click="navigateTo('/brand')">
        <img class="logo-icon" alt="" src="@/assets/img/bottom_menu/icon_brand.svg" />
        <div class="div">브랜드 소개</div>
      </div>
      <div :class="['tab-2', isActive('/guide/storage')]" @click="navigateTo('/guide/storage')">
        <img class="icon-guide" alt="" src="@/assets/img/bottom_menu/icon_guide.svg" />
        <div class="div1">사용가이드</div>
      </div>
      <div :class="['tab-3', isActive('/')]" @click="navigateTo('/')">
        <img class="icon-guide" alt="" src="@/assets/img/bottom_menu/icon_search.svg" />
        <div class="div1">견적보기</div>
      </div>
      <div :class="['tab-4', isActive('/my')|| isActive('/login') || isActive('/support')]" @click="navigateTo('/my')">
        <div class="icon-guide">
          <div class="div3" />
          <div class="div4" />
        </div>
        <div class="div1">마이페이지</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRoute, useRouter } from 'vue-router';

const router = useRouter()
const route = useRoute();

function navigateTo(page) {
  router.push({ path: page });
}

function isActive(page) {
  const currentPath = route.path;

  return currentPath === page || currentPath.startsWith(page + '/') ? 'active' : '';
}
</script>

<style scoped>
.logo-icon {
  width: 40px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.div {
  position: relative;
  line-height: 100%;
  font-weight: 300;
}
.icon-guide {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.div1 {
  width: 44px;
  position: relative;
  line-height: 100%;
  font-weight: 300;
  display: inline-block;
}
.tab-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  opacity: 0.5;
}
.tab-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 3px;
  opacity: 0.5;
}
.tab-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 3px;
  opacity: 0.5;
}
.tab-4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 3px;
  opacity: 0.5;
}
.div3 {
  position: absolute;
  top: 2px;
  left: calc(50% - 3.5px);
  border-radius: 50%;
  border: 1px solid #fff;
  box-sizing: border-box;
  width: 7px;
  height: 7px;
}
.div4 {
  position: absolute;
  top: 11px;
  left: calc(50% - 8.5px);
  border-radius: 3px 3px 0 0;
  border: 1px solid #fff;
  box-sizing: border-box;
  width: 17px;
  height: 9px;
}
.layout {
  position: absolute;
  top: 10px;
  left: calc(50% - 154.5px);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 44px;
}
.bottom-nv {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #000;
  height: 78px;
  overflow: hidden;
  text-align: center;
  font-size: 9px;
  color: #fff;
  font-family: 'Noto Sans CJK KR';
}
.active {
  opacity: 1;
  color: #fff; /* 강조된 상태의 스타일 */
}
</style>
