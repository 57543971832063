import _api from './api'
import _endpoints from './endpoints'
import _const from "../store/const";

function setHeaders(accessToken, refreshToken) {
    localStorage.setItem(_const.TOKEN, accessToken);
    _api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

    if (refreshToken) {
        localStorage.setItem(_const.REFRESH_TOKEN, refreshToken);
        _api.defaults.headers.common['refresh-token'] = `${refreshToken}`;
    }
}

export default {
    async refreshReset () {
        let { data } = await _api.post(_endpoints.refresh_token_reset);
        return data;
    },

    async getProfile () {
        return await _api.get(_endpoints.get_profile);
    },

    async checkID (id) {
        return await _api.get(_endpoints.check_id.replace(':id', id));
    },

    async sendSMS (username) {
        return await _api.post(_endpoints.reset_passwd_sms_send, {
            username
        });
    },

    async validationSMS (username, number) {
        return await _api.post(_endpoints.reset_passwd_sms_send_validation, {
            username, number
        });
    },

    async resetPasswd (username, passwd) {
        return await _api.put(_endpoints.reset_passwd, {
            username, passwd
        });
    },

    async updateProfile (profile) {
        return await _api.put(_endpoints.get_profile, profile);
    },

    async certification (id, data) {
        return await _api.post(_endpoints.certification.replace(':id', id), data);
    },

    async signup (obj) {
        let { data } = await _api.post(_endpoints.signup, obj);

        return data;
    },

    async signupSns (type, obj) {
        let { data } = await _api.post(_endpoints.signup_sns.replace(':type', type), obj);

        if (data && data['access_token']) {
            setHeaders(data['access_token'], data['refresh_token']);
        }

        return data;
    },

    async updatePassword (passwd) {
        return await _api.put(_endpoints.passwd_update, { passwd });
    },

    async withdraw () {
        return await _api.delete(_endpoints.withdraw);
    },

    async getAddressList () {
        let { data } = await _api.get(_endpoints.get_address_list);
        return data;
    },

    async getPaymentList () {
        let { data } = await _api.get(_endpoints.get_payment_list);
        return data;
    },

    async addPayment (obj) {
        let { data } = await _api.post(_endpoints.get_payment_list, obj);
        return data;
    },

    async deletePayment (id) {
        let { data } = await _api.delete(_endpoints.payment_id.replace(':id', id));
        return data;
    },

    async updateAddress (id, obj) {
        return await _api.put(_endpoints.address_id.replace(':id', id), obj);
    },

    async deleteAddress (id) {
        return await _api.delete(_endpoints.address_id.replace(':id', id));
    },

    async addAddress (obj) {
        return await _api.post(_endpoints.get_address_list, obj);
    },

    /**
     * (기업전용) 회사 인보이스 목록 반환.
     * @returns {Promise<any>}
     */
    async getCompanyInvoiceList () {
        let { data } = await _api.get(_endpoints.user_company_payments);
        return data;
    },

    async requestCompanyPayment (obj) {
        let { data } = await _api.post(_endpoints.request_user_company_payments.replace(':cpay_id', obj.cpayment_id), {
            payment_id: obj.payment_id
        });

        return data;
    },

    /**
     * (기업전용) 회사 박스 목록 반환.
     * @param page
     * @param limit
     * @param filter
     * @returns {Promise<any>}
     */
    async getCompanyBoxList (page, limit, filter) {
        let { data } = await _api.get(_endpoints.user_company_box_list, {
            params: {
                page, limit, filter
            }
        });
        return data;
    },

    /**
     * (기업전용) 회사 작업 목록 반환.
     * @param page
     * @param limit
     * @param filter
     * @returns {Promise<any>}
     */
    async getCompanyWorkList (page, limit) {
        let { data } = await _api.get(_endpoints.user_company_work_list, {
            params: {
                page, limit
            }
        });
        return data;
    },

    /**
     * (기업전용) 일반결제 - 결제완료
     * @param obj
     * @returns {Promise<any|null>}
     */
    async paymentCompleteCompanyPayment(obj) {
        const { data } = await _api.post(_endpoints.user_company_payment_complete, obj);
        return data || null;
    },

    /**
     * (기업전용) 박스 배송요청 금액 반환
     * @param obj
     * @returns {Promise<any|null>}
     */
    async getCompanyBoxDeliveryAmount(obj) {
        const { data } = await _api.post(_endpoints.user_company_delivery_amount, obj);
        return data || null;
    },

    /**
     * (기업전용) 박스 배송요청
     * @param obj
     * @returns {Promise<any|null>}
     */
    async requestCompanyBoxDelivery(obj) {
        const { data } = await _api.post(_endpoints.user_company_delivery, obj);
        return data || null;
    }
}
