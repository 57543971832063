export default {
    GOOGLE_ID: '899197828132-m4ncb79a1gnf87tes3p6appjghpn1u1e.apps.googleusercontent.com',
    GOOGLE_REDIRECT_URI: '/login/google/callback',

    TOKEN: 'lAsvGuiHJd',
    REFRESH_TOKEN: 'rwAbBd.aOjk8a',

    SET_IS_INIT: 'setIsInit',
    SET_LOGIN_ERROR: 'setLoginError',
    SET_IS_LOGIN: 'setIsLogIn',
    SET_AUTH: 'setAuth',
    SET_PROFILE: 'setProfile',
    SET_SNS_SIGNUP: 'setSnsSignup',

    SET_IMP: 'setImp',

    SET_FAQ_CATEGORIES: 'setFaqCategories',

    SET_POPUP_LIST: 'setPopupList',
    SET_MOBILE_SIDE_MENU: 'setSideMenu',

    STORAGE_ORDER_TYPE: {
        FIRST_TIME_ORDER: 'FIRST_TIME_ORDER',
        DELIVERY_REQUEST_ORDER: 'DELIVERY_REQUEST_ORDER',
        FINAL_AND_DELIVERY_ORDER: 'FINAL_AND_DELIVERY_ORDER',
        FINAL_AND_DISPOSAL_ORDER: 'FINAL_AND_DISPOSAL_ORDER',
    },

    FORM_ERROR: {
        start_add: {
            require: '출발지를 입력해주세요.'
        },
        start_add_detail: {
            require: '출발지 상세주소를 입력해주세요.'
        },
        start_add_floor: {
            require: '출발지 층수를 입력해주세요.',
            carriage: '출발지의 운반방식이 누락되었습니다. (ex.엘리베이터)'
        },
        dest_add: {
            require: '도착지를 입력해주세요.'
        },
        dest_add_detail: {
            require: '도착지 상세주소를 입력해주세요.'
        },
        dest_add_floor: {
            require: '도착지 층수를 입력해주세요.',
            carriage: '도착지의 운반방식이 누락되었습니다. (ex.엘리베이터)'
        },
        address: {
            require: '주소를 입력해주세요.'
        },
        address_detail: {
            require: '상세주소를 입력해주세요.'
        },
        address_floor: {
            require: '주소지의 층수를 입력해주세요.',
            carriage: '주소지의 운반방식이 누락되었습니다. (ex.엘리베이터)'
        },
        work_date: {
            require: '운송일을 입력해주세요.'
        },
        work_time: {
            require: '운송 시간을 선택해주세요.'
        },
        delivery_date: {
            require: '배송일을 입력해주세요.'
        },
        delivery_time: {
            require: '배송 시간을 선택해주세요.'
        },
        collection_date: {
            require: '수거일을 입력해주세요.'
        },
        collection_time: {
            require: '수거 시간을 선택해주세요.'
        },
        storage_delivery: {
            diff: '보관 박스 배송-수거 기간은 최대 7일까지 가능합니다'
        },
        period: {
            require: '보관기간을 선택해주세요.'
        },
        box_cnt: {
            require: '박스 개수를 입력해주세요.'
        },
        is_use_box: {
            require: '밥아저씨 박스 사용여부를 선택해주세요.'
        },
        is_heavy: {
            require: '20kg 이상 여부를 선택해주세요.'
        },
        is_require_addition: {
            require: '추가도움 여부를 선택해주세요.'
        },
        spec: {
            require: '옮길 짐을 선택해주세요.'
        },
    },

    MENUS: [{
        title: "브랜드 소개",
        link: "/brand",
    }, {
        title: "견적보기",
        link: "/estimate"
        // page: 'estimate'
        // subs: [{
        //     title: "SELLING PRODUCTS",
        //     link: "/products/selling"
        // }, {
        //     title: "SOLD PRODUCTS",
        //     link: "/products/sold"
        // }]
    }, {
        title: "마이페이지",
        link: "/mypage"
    }, {
        title: "FAQ",
        link: "/notices"
    }],

    MAIN_MENU_MOBILE: [{
        title: "LOGIN",
        link: "/login"
    }],

    CARRIAGE_TYPE: {
        STAIRS: 'STAIRS',
        EV: 'EV',
        LADDER: 'LADDER'
    },
    CARRIAGE_TYPE_STORAGE: {
        STAIRS: 'STAIRS',
        EV: 'EV',
    },
    CARRIAGE_TYPE_KR: {
        STAIRS: '계단',
        EV: '엘리베이터',
        LADDER: '사다리차'
    },
    CARRIAGE_TYPE_KR_STORAGE: {
        STAIRS: '계단',
        EV: '엘리베이터',
    },

    MAX_WORK_DATE: 90,

    PAYMENT_TYPE: {
        ONLY_NORMAL_PAYMENT: 'ONLY_NORMAL_PAYMENT',
        ONLY_SUBS_PAYMENT: 'ONLY_SUBS_PAYMENT',
        ALL: 'ALL'
    },

    PAYMENT: {
        USERCODE: 'imp44550056',
        NORMAL: 'tosspayments.im_biarkewmk1',
        BILLING: 'tosspayments.bill_im_bixy8m',

        METHODS: [
            {
                value: 'card',
                label: '신용카드',
                icon: 'credit-card'
            },
            // {
            //     value: 'trans',
            //     label: '실시간 계좌이체',
            //     icon: 'money'
            // },
            {
                value: 'kakaopay',
                label: '카카오페이',
                icon: 'kakaotalk',
                img: 'https://bob-prod-bucket.s3.ap-northeast-2.amazonaws.com/icn_kakao_pay.png',
            },
            {
                value: 'naverpay',
                label: '네이버페이',
                icon: 'naver',
                img: 'https://bob-prod-bucket.s3.ap-northeast-2.amazonaws.com/icn_naver_pay.png',
            },
            {
                value: 'tosspay',
                label: '토스페이',
                icon: 'credit-card',
                img: 'https://bob-prod-bucket.s3.ap-northeast-2.amazonaws.com/icn_toss_pay.png',
            }
        ],

        PGS: [
            {
                value: 'kakaopay',
                label: '카카오페이',
                icon: 'credit-card'
            },
            {
                value: 'naverpay',
                label: '네이버페이',
                icon: 'naver'
            },
        ]
    },

    MOVE_STATUS: {
        DEFAULT_STYLE: 'primary-border',
        WAIT: {
            label: '결제실패',
            style: 'primary-border'
        },
        PAYMENT: {
            label: '박스 준비중',
            style: 'primary-border'
        },
        CONFIRM: {
            label: '박스 준비중',
            style: 'primary-border'
        },
        BOX_DEL_BEFORE: {
            label: '박스 준비중',
            style: 'primary-border'
        },
        BOX_DEL_COMPLETE: {
            label: '박스 배송완료',
            style: 'primary-border'
        },
        WORKING: {
            label: '배송중',
            style: 'primary-border'
        },
        WORKING_COMPLETE: {
            label: '회수 대기 중',
            style: 'primary-border'
        },
        BOX_COL_REQUEST: {
            label: '회수 대기 중',
            style: 'primary-border'
        },
        COMPLETE: {
            label: '완료',
            style: 'primary'
        },
        CANCEL: {
            label: '취소',
            style: 'disabled'
        }
    },

    DELIVERY_STEPS: ['박스준비중', '배송중', '배송완료', '수거중', '수거완료'],
    DELIVERY_STATUS_AT_STEP: {
        'BEFORE_DELIVERY': 1,
        'DELIVERING': 2,
        'BEFORE_COLLECTION': 3,
        'COLLECTING': 4,
        'COMPLETE': 5
    },

    COMPANY_WORK_STATUS_STR: {
        'WAIT': '관리자 확인 대기중',
        'CONFIRM': '확인/진행',
        'INVOICE_COMPLETE': '결제대기중',
        'PAYMENT': '결제완료',
        'COMPLETE': '종료',
    }
}
