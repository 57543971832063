export default {
    login: '/api/login/:type',
    login_for_naver: '/api/login/naver',
    login_for_kakao: '/api/login/kakao',
    login_for_google: '/api/login/google',
    login_for_apple: '/api/login/apple',
    login_for_sns: '/api/login/:type',
    logout: '/logout',

    schedule_box_delivery_is_available: '/api/schedule/box/delivery/available',
    schedule_box_delivery: '/api/schedule/box/delivery/day/:day',

    get_certifications: '/api/users/certifications',
    get_id_by_certifications: '/api/users/certifications/:uid/id',

    my_estimates: '/api/estimates',
    get_estimate_by_serial: '/api/estimates/:serial',
    request_collection: '/api/estimates/:serial/collection',
    update_collection: '/api/estimates/:serial/collection',
    cancel_estimate: '/api/estimates/:serial/cancel',
    update_review: '/api/estimates/:serial/review',

    // deprecated
    my_storage_contract_boxes: '/api/storage/contracts/boxes',
    add_storage_contracts: '/api/storage/contracts',
    get_storage_contract_by_serial: '/api/storage/contracts/:serial',

    // v2
    get_contract_price: '/api/storage-orders/contract-amounts',
    get_storage_order_price: '/api/storage-orders/type/:type/amount',
    add_storage_contracts_v2: '/api/storage-orders',
    add_storage_contracts_v2_for_event3: '/api/storage-orders/event3',
    storage_payment_complete_v2: '/api/storage-orders/payments/complete',
    get_storage_contract_by_serial_v2: '/api/storage-orders/:serial',
    storage_contract_cancel_v2: '/api/storage-orders/:serial/cancel',
    get_my_storage_orders: '/api/storage-orders',
    storage_order_serial: '/api/storage-orders/:serial',
    storage_order_serial_payment_id: '/api/storage-orders/:serial/payments/:id',
    storage_contracts_by_serial: '/api/storage-orders/:serial/contracts',
    storage_finished_boxes_by_serial: '/api/storage-orders/:serial/finished-boxes',
    storage_payments_by_serial: '/api/storage-orders/:serial/payments',
    get_available_delivery_boxes_by_serial: '/api/storage-orders/:serial/available-delivery-boxes',
    storage_contract_box_id: '/api/storage-orders/boxes/:usbox_id/content',
    get_storage_finish_delivery_amount: '/api/storage-orders/:id/finish-delivery-amount',
    storage_finish_dispose: '/api/storage-orders/:serial/finish-dispose',
    get_storage_finish_dispose_amount: '/api/storage-orders/:id/finish-dispose-amount',

    storage_delivery: '/api/storage-delivery',
    storage_finish_delivery: '/api/storage-delivery/finish',
    storage_delivery_serial: '/api/storage-delivery/:serial',
    storage_delivery_cancel: '/api/storage-delivery/:serial/cancel',
    delivery_boxes_by_serial: '/api/storage-delivery/:serial/boxes',
    storage_delivery_paid_by_serial: '/api/storage-delivery/:serial/payment-complete',
    get_storage_delivery_amount: '/api/storage-delivery/amount',

    get_contract_termination_price: '/api/storage-contracts/:id/termination-amount',
    contract_termination: '/api/storage-contracts/:id/termination',
    contract_upgrade: '/api/storage-contracts/:id/upgrade/:type',
    contract_extension: '/api/storage-contracts/:id/extension/:type',

    storage_final: '/api/storage-final',
    storage_final_serial: '/api/storage-final/:serial',
    get_storage_final_amount: '/api/storage-final/amount',

    get_estimate_storage_amount_with_move: '/api/storage/contracts/amount/integration',
    get_estimate_storage_amount_initial: '/api/storage/contracts/amount/initial',
    get_estimate_storage_amount_extension: '/api/storage/contracts/amount/extension',
    get_estimate_storage_amount_delivery: '/api/storage/contracts/amount/delivery',
    get_estimate_storage_amount_final: '/api/storage/contracts/amount/final',
    get_estimate_storage_amount_dispose: '/api/storage/contracts/amount/dispose',
    get_estimate_storage_amount_simple: '/api/storage/contracts/amount/simple',

    storage_payment_complete: '/api/storage/contracts/payments/complete',
    extend_storage_boxes: '/api/storage/contracts/boxes/extension',
    delivery_storage_boxes: '/api/storage/contracts/boxes/delivery',
    final_storage_boxes: '/api/storage/contracts/boxes/final',
    dispose_storage_boxes: '/api/storage/contracts/boxes/dispose',
    is_delivery_available: '/api/storage/contracts/delivery/available',

    storage_contract_box_content_register: '/api/boxes/:serial/content/first',
    storage_contract_box_content_register_img: '/api/boxes/:serial/content/first/picture',
    storage_contract_box_content_by_serial: '/api/boxes/:serial/content',
    storage_contract_box_simple_content_by_serial: '/api/boxes/:serial/content/simple',
    storage_contract_box_content_registered_by_serial: '/api/boxes/:serial/content/registered',

    schedule_day_list: '/api/schedule/day',
    schedule_time_list_by_day: '/api/schedule/day/:day',
    move_specification_list: '/api/specs/move',

    estimate_amount: '/api/estimates/amount',
    estimate_amount_simple: '/api/estimates/amount/simple',
    add_estimate: '/api/estimates',
    add_estimate_with_storage: '/api/estimates/integration',
    payment_complete: '/api/estimates/payments/complete',


    files: '/api/files',

    check_id: '/api/users/id/:id/valid',
    reset_passwd_sms_send: '/api/certification/sms/passwd',
    reset_passwd_sms_send_validation: '/api/certification/sms/passwd/validation',
    reset_passwd: '/api/reset/passwd',

    signup: '/api/signup',
    signup_sns: '/api/signup/:type',
    check_passwd: '/api/users/my/auth',
    address_update: '/api/users/address',
    passwd_update: '/api/users/password',
    certification: '/api/users/:id/certification',
    withdraw: '/api/users/my',

    get_profile: '/api/users/my',
    get_address_list: '/api/users/my/address',
    get_payment_list: '/api/users/my/payments',
    payment_id: '/api/users/my/payments/:id',
    address_id: '/api/users/my/address/:id',

    refresh_token_reset: '/api/token/refresh/reset',

    get_popups: '/api/popups',

    get_custom_payment: '/api/payments/custom/:url',
    custom_payment_complete: '/api/payments/custom/:url/complete',

    my_coupons: '/api/coupons/:type/available',

    user_company_payments: '/api/users/my/company/payments',
    request_user_company_payments: '/api/users/my/company/payments/:cpay_id/payment',
    user_company_box_list: '/api/users/my/company/boxes',
    user_company_work_list: '/api/users/my/company/works',
    user_company_payment_complete: '/api/users/my/company/payment-complete',
    user_company_delivery_amount: '/api/users/my/company/delivery/amount',
    user_company_delivery: '/api/users/my/company/delivery',

    company_box_list_by_work_id: '/api/companies/works/:wk_id/boxes',
    company_work_by_id: '/api/companies/works/:wk_id',
}
