<template>
  <header class="header" v-bind:class="{ login: $route.name == 'Login', 'display-sidebar': isShowSidebar, 'grey-bg': $route.meta.header === 'grey' }">
    <div class="container">
      <div class="container-fluid nav">
        <router-link rel="nosublink" to="/" class="brand" @click="$helper.sendGA('click_btn_nav', { 'target': 'brand' })">
          <img src="~assets/img/logo_bob_black.svg" alt="BOB">
        </router-link>

        <div class="desktop-main-menu-bar">
          <router-link rel="nosublink" to="/guide/storage"
                       class="tablet-hide desktop-main-menu"
                       :class="{ 'active': this.$route.meta.menu === 'guide' }"
                       @click="$helper.sendGA('click_btn_nav', { 'target': 'guide_storage' })">사용가이드</router-link>

          <router-link rel="nosublink" to="/"
                       class="tablet-hide desktop-main-menu"
                       :class="{ 'active': this.$route.meta.menu === 'estimate' }"
                       @click="$helper.sendGA('click_btn_nav', { 'target': 'estimate_storage' })">견적보기</router-link>
        </div>


        <!--   sidebar     -->
        <div class="sidebar">
          <div class="top-container" :class="{ 'guest': !isLogin }">
            <button class="btn-icon btn-close" @click="toggleMainSideMenu">
              <i class="xi-close"></i>
            </button>

            <div class="user-info-row">
              <div class="profile-img">
                <i class="xi xi-user"></i>
              </div>
              <div class="txt-wrap">
                <div class="logged-in" v-if="isLogin">{{ $store.state.auth.profile.name }} <span>님</span></div>
                <router-link rel="nosublink" to="/login" @click="toggleMainSideMenu" v-else>{{ isLogin ? username : '로그인이 필요합니다.' }}</router-link>
                <button v-if="isLogin"  class="logout"
                        @click="link('/logout'); $helper.sendGA('click_btn_nav', { 'target': 'logout' })"
                ><i class="xi xi-log-out"></i>로그아웃</button>
              </div>
            </div>

            <div v-if="isLogin" class="top-sub-menu">
              <button @click="link('/my'); $helper.sendGA('click_btn_nav', { 'target': 'mypage' })">
                <i class="xi xi-pen-o"></i>
                마이페이지
              </button>
            </div>
          </div>

          <div class="mobile-sub-menu-container">
            <button class="nav-item" @click="link('/brand'); $helper.sendGA('click_btn_nav', { 'target': 'brand' })">브랜드 소개</button>
            <button class="nav-item" @click="link('/guide/storage'); $helper.sendGA('click_btn_nav', { 'target': 'guide_storage' })">사용가이드</button>
            <button class="nav-item" @click="link('/'); $helper.sendGA('click_btn_nav', { 'target': 'estimate_storage' })">견적보기</button>
            <button class="nav-item" @click="link('/support/main'); $helper.sendGA('click_btn_nav', { 'target': 'support' })">고객센터</button>
          </div>

        </div>
        <div class="right-menus">
          <div class="menu" v-on:click="toggleMainSideMenu">
            <i class="xi-bars xi-2x"></i>
          </div>
          <router-link class="btn btn-support" to="/support/main"
                       @click="$helper.sendGA('click_btn_nav', { 'target': 'support' })"
          >고객센터</router-link>
        </div>
        <div class="menu-block" v-on:click="toggleMainSideMenu"></div>
      </div>
      <nav class="sublink-nav">
        <a href="/guide">사용가이드</a>
        <a href="/estimate/simple">간편견적보기</a>
        <a href="/estimate">상세견적보기</a>
        <a href="/support/main">고객센터</a>
      </nav>
    </div>
  </header>

</template>

<script>

require('../../extensions/kakao.min.js');

// import NavItem from "./NavItem";
// import TheMobileSideBar from "../TheMobileSideBar"
import _CONST from "../../store/const";
// import {useStore} from "vuex";

export default {
  name: "Header",
  // components: {
  //   NavItem,
  //   TheMobileSideBar
  // },
  data() {
    return {
      mobileMenu: _CONST.MAIN_MENU_MOBILE,
      activeMainMenu: null,
      isShowSidebar: false,
      isMobileSubmenu: {
        estimate: false,
        guide: false
      },
      username: null
      // loginButton: {
      //   title: 'LOGIN',
      //   link: '/login'
      // }
    }
  },
  props: [ 'title' ],
  methods: {
    onClickSubParentMenu(key) {
      let prev = this.isMobileSubmenu[key];

      this.isMobileSubmenu.estimate = false;
      this.isMobileSubmenu.guide = false;

      if (!prev) this.isMobileSubmenu[key] = !prev;
    },
    toggleMainSideMenu() {
      this.isMobileSubmenu.estimate = false;
      this.isMobileSubmenu.guide = false;

      this.isShowSidebar = !this.isShowSidebar;
      this.$store.dispatch('common/toggleSideMenu', !this.$store.state.common.isSideMenuShow);
    },

    link(link) {
      this.toggleMainSideMenu();
      this.$router.push(link);
    },

    openKakaoChannel() {
      this.$helper.sendGA('click_btn_cs', { 'target': 'kakao_cs' });
      window.Kakao.Channel.chat({
        channelPublicId: '_MxkVTxj'
      });
    }
  },
  setup() {
    // const store = useStore();

    const logout = async () => {
      await this.$store.dispatch('logout');
      window.location.href = '/';
    }
    return {
      logout,
    };
  },
  async mounted() {
    window.__kakaoInit();
    this.isShowSidebar = this.$store.state.common.toggleSideMenu;
    this.username = this.$store.state.auth.profile.name;
    //
    // if (!this.$store.state.notification.init) {
    //   await this.$store.dispatch('notification/loadNewNotifications');
    // }
    //
    // this.notifications = this.$store.state.notification.notifications;

  },
  computed: {
    isLogin() {
      return this.$store.state.auth.isLogin;
    }
  },
  unmounted() {
    document.body.classList.remove('scroll-lock');
  },
  watch: {
    isShowSidebar() {
      if (this.isShowSidebar) {
        document.body.classList.add('scroll-lock');
      } else {
        document.body.classList.remove('scroll-lock');
      }
    }



    // role(val) {
    //   this.role = val;
    // }
  }
  // mounted() {
  //   const store = useStore();
  //
  //   if (store.state.auth.isLogin) {
  //     this.loginButton.title = 'LOGOUT';
  //     this.loginButton.link = '/logout';
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.sublink-nav {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  font-size: 0px;
}
.header  {
  //background-color: $main-color;
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid #D0D4DC;

  &.grey-bg {
    background-color: #EDEFF2;
    border-bottom: 0px;
  }

  .container-fluid {
    padding: 0;
  }

  .desktop-main-menu-bar {
    margin-left: 56px;
  }

  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $header-height;
    font-weight: bold;
    //font-family: SBAggroL;

    .pc-logout {
      color: #000;
      vertical-align: top;
      line-height: 24px;
      margin-right: 40px;
      text-decoration: none;
      border-bottom: 1px solid #000;
      font-family: NotoSansKR;
      font-weight: normal;
      font-size: 14px;
    }

    .btn-pc-mypage {
      font-size: 24px;
      color: #000;
    }

    .desktop-main-menu {
      position: relative;
      text-decoration: none;
      padding: 8px 0;
      margin: 0 23px;
      display: inline-block;
      color: #000;
      text-align: center;
      //border-radius: 20px;
      transition:  background-color .3s ease-in-out;
      z-index: 1;
      font-size: 17px;
      font-weight: 500;

      span {
        padding-bottom: 6px;
      }

      &.active {
        border-bottom: 2px solid #2b5fc9;
        //span {
        //  border-bottom: 2px solid #2b5fc9;
        //}
      }
    }

    @include respond(mobile) {
      height: $header-m-height;
      width: 100%;
    }
  }

  .desktop-sub-menu-wrap {
    position: absolute;
    display: none;
    background-color: $sub-color;
    left: 50%;
    top: 100%;
    width: 96px;
    margin-left: -48px;
    padding: 20px 0;
    text-align: center;
    @include border-round(10px);

    .desktop-sub-menu-item {
      color: #f6f8fb;
      display: block;
      white-space: nowrap;
      text-decoration: none;
      line-height: 25px;
      font-size: 17px;
      opacity: 0.6;

      & + .desktop-sub-menu-item {
        padding-top: 7px;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  .desktop-sub-menu-target {
    cursor: default;

    &:hover {
      .desktop-sub-menu-wrap {
        display: block;
      }
    }
  }

  .mobile-sub-menu-wrap {
    display: block;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s;
    margin-left: -33px;

    .bg {
      margin-top: 20px;
      background-color: #e9effa;
    }

    .mobile-sub-menu-item {
      background-color: transparent;
      border: 0;
      display: block;
      color: #000;
      margin: 0 auto 0 45px;
      font-size: 18px;
      font-weight: bold;
      padding-top: 28px;
      cursor: pointer;

      + .mobile-sub-menu-item {
        padding-top: 25px;
        padding-bottom: 28px;
      }
    }
  }

  .mobile-sub-menu-target {
    cursor: default;

    &.active {
      //span {
      //  margin-top: 28px;
      //}

      .mobile-sub-menu-wrap {
        max-height: 150px;
      }
    }
  }

  .brand {
    font-size: 40px;
    font-weight: bold;
    font-family: "montserrat", sans-serif;
    color: #000;
    text-decoration: none;
    width: 85px;

    @include respond(mobile) {
      font-size: 25px;
      width: 55px;
    }
  }

  .content{
    background-color: #fff;
    position: absolute;
    top:50%;
    right: 50%;
    transform: translate(-50%,-50%);
  }

  .btn-kakao {
    border: 1px solid #000;
    height: 45px;
    line-height: 45px;
    background-color: transparent;
    transition: background-color 0.5s, color 0.5s;
    padding: 0 25px;
    @include border-round(23px);

    > div {
      position: relative;
      display: inline-block;
      width: 21px;
      margin-top: 12px;
      height: 100%;
    }

    img {
      position: absolute;
      left: 0;
      width: 21px;
      height: auto;
      transition: opacity 0.5s ease-in-out;

      &.hover {
        opacity: 0;
      }
    }

    span {
      vertical-align: top;
      padding-left: 5px;
      font-size: 15px;
      line-height: 43px;
      font-weight: bold;
    }

    @include respond(desktop) {
      &:hover {
        background-color: #000;
        color: #fff;

        img.default {
          opacity: 0;
        }
        img.hover {
          opacity: 1;
        }
      }
    }

    @include respond(tablet) {
      > div {
        margin-top: 0;
      }
    }
  }


  .btn-support {
    border: 1px solid #000;
    height: 38px;
    background-color: transparent;
    transition: background-color 0.5s, color 0.5s;
    padding: 0 23px;
    line-height: 36px;
    font-weight: bold;
    font-size: 15px;
    color: #000;
    @include border-round(24px);

    @include respond(desktop) {
      &:hover {
        background-color: #000;
        color: #fff;
      }
    }

    @include respond(tablet) {
      padding: 0 13px;
      font-size: 12px;
      height: 30px;
      line-height: 28px;
    }
  }

  .menu {
      float: right;
      margin-top: 4px;
      margin-left: 30px;
  }


  // sidebar
  .sidebar {
    position: fixed;
    display: flex;
    flex-direction: column;
    right: -500px;
    top: 0;
    transition: 0.3s ease-in;
    width: 500px;
    background-color: #fff;
    color: #000;
    height: 100vh;
    z-index: 2;
  }

  .top-container {
    background-color: $sub-color;
    color: #fff;
    height: 250px;
    flex-shrink: 0;

    button {
      border: none;
      background: none;
    }
  }

  .mobile-sub-menu-container {
    flex-grow: 1;
    overflow: auto;
  }

  .nav .sidebar .nav-item {
    padding: 30px 0 0 33px;
    display: block;
    width: 100%;
    margin: 0;
    border: 0;
    background-color: transparent;
    outline: none;
    font-size: 20px;
    line-height: 34px;
    text-align: left;
    font-weight: 800;
    cursor: pointer;

    &:last-child {
      padding-bottom: 30px;
    }
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 35px;
    background-color: transparent;
    padding: 22px;
    border: none;
    color: #fff;
  }

  .user-info-row {
    padding: 70px 30px 40px;
    text-align: left;
  }

  .txt-wrap {
    display: inline-block;
    vertical-align: top;
    padding-left: 20px;
    padding-top: 10px;
    max-width: calc(100vw - 90px);

    div, a {
      font-size: 22px;
      line-height: 44px;
      letter-spacing: -0.3px;
      color: #fff;
      text-decoration: none;
    }

    a {
      opacity: 0.6;
    }

    .logged-in {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        font-weight: normal;
      }
    }

    .logout {
      color: #6c93e4;
      font-size: 12px;
      vertical-align: top;
      padding: 0;
      cursor: pointer;

      i {
        margin-right: 3px;
      }
    }
  }

  .profile-img {
    display: inline-block;
    font-size: 80px;
    background-color: #1a4aac;
    width: 80px;
    height: 80px;
    overflow: hidden;
    @include border-round(100%);

    i {
      opacity: 0.25;
      vertical-align: top;
      padding-top: 15px;
    }
  }

  .top-sub-menu {
    text-align: center;

    button {
      font-size: 19px;
      line-height: 32px;
      color: #fff;
      font-weight: bold;
      padding: 0;
      cursor: pointer;

      i {
        margin-right: 3px;
      }
    }

    span {
      opacity: 0.2;
      line-height: 32px;
      vertical-align: top;
      padding: 0 70px;
    }
  }

  .top-container.guest {
    .user-info-row {
      padding-top: 80px;
    }
    .txt-wrap {
      padding-top: 15px;
    }
  }

  @include respond(tablet) {
    .nav {
      .sidebar {
        width: 100%;
        right: -100%;

        .nav-item {
          font-size: 18px;
          padding: 25px 0 0 25px;
          color: #000;
        }
      }
    }

    .btn-kakao {
      height: 35px;
      line-height: 35px;
      padding: 0 17px;
      @include border-round(23px);

      img {
        width: 17px;
        margin-top: 9px;
      }

      span {
        padding-left: 2px;
        font-size: 11px;
        line-height: 33px;
        color: #000;
      }
    }

    .right-menus {
      padding-top: 3px;
    }

    .menu {
      margin-top: 0;
      margin-left: 15px;
    }

    .top-container {
      height: 190px;
    }

    .user-info-row {
      padding-top: 53px;
      padding-bottom: 30px;
    }

    .top-sub-menu {
      button {
        font-size: 15px;
        line-height: 25px;
      }

      span {
        line-height: 24px;
        padding: 0 13vw;
      }
    }

    .btn-close {
      font-size: 30px;
      padding: 15px 17px;
    }

    .profile-img {
      font-size: 60px;
      width: 60px;
      height: 60px;

      i {
        padding-top: 11px;
      }
    }

    .txt-wrap {
      padding-left: 15px;
      padding-top: 4px;

      div, a {
        font-size: 23px;
        line-height: 34px;
        letter-spacing: -0.23px;
      }
    }

    .top-container.guest {
      .user-info-row {
        padding-top: 65px;
      }
      .txt-wrap {
        padding-top: 10px;
      }
    }

    .mobile-sub-menu-wrap {
      margin-left: -25px;

      .mobile-sub-menu-item {
        font-size: 15px;
        margin-left: 35px;
        padding-top: 21px;

        + .mobile-sub-menu-item {
          padding-bottom: 21px;
        }
      }
    }

  }

  .menu-block {
    position: fixed;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
  }

  //.menu {
  //  @include respond(desktop) {
  //    display: none;
  //  }
  //}

  .page-header {
    height: 16.5625rem;
    padding-top: 7.875rem;
    font-size: 2.8125rem;
    font-family: SBAggroM;

    @include respond(mobile) {
      font-size: 32px;
      height: 120px;
      padding-top: 55px;
    }

    @include respond(xs-mobile) {
      letter-spacing: -0.5px;
    }
  }

  @include respond(xs-mobile) {
    .user-info-row {
      padding-left: 20px;
      padding-right: 20px;
    }
    .profile-img {
      width: 50px;
      height: 50px;
      font-size: 50px;
    }

    .txt-wrap a {
      font-size: 20px;
    }
  }
}


.header.display-sidebar {
  .sidebar {
    right: 0;
  }

  .menu-block {
    display: block;
  }
}

body.white-header {
  .header {
    background-color: #fff;
  }
}


//@import 'https://fonts.googleapis.com/css2?family=Amiri&display=swap';
//
//.btn-menu {
//  cursor: pointer;
//  background: url('~assets/img/icons/mobile_main_menu@2x.png') no-repeat center;
//  background-size: 20px 4px;
//}
//
//#logo {
//  font-family: 'Amiri', serif;
//  font-size: 1.2rem;
//  color: #fff;
//}
//
//.nav {
//  position: absolute;
//  width: 100%;
//  min-height: 75px;
//  height: auto;
//  z-index: 2;
//  background-color: #1d1d1d;
//
//  transition-property: min-height, height, background-color;
//  transition-duration: 500ms;
//
//  &.main {
//    background-color: transparent;
//
//    &:hover {
//      //height: 187px;
//      background-color: #1d1d1d;
//    }
//  }
//
//  @include respond(mobile) {
//    height: 50px;
//    min-height: 50px;
//
//    .nav-item-wrap {
//      min-height: 50px;
//      line-height: 50px;
//    }
//
//    .btn-menu {
//      width: 50px;
//      height: 50px;
//    }
//  }
//
//  > .main-menu {
//    //padding-top: 24px;
//  }
//}
//
//.header-logo {
//  img {
//    height: 40px;
//    margin-top: 0.8rem;
//
//    @include respond(mobile) {
//      height: 30px;
//      margin-left: 0.5rem;
//    }
//  }
//}
</style>
