import authApi from '../../api/auth';
import userApi from '../../api/user';
import _const from '../const';
import api from '../../api/api';

const state = {
    isLogin: false,
    isInit: false,
    loginError: '',
    auth: {},
    imp: {
        imp_uid: null,
        merchant_uid: null,
        gender: null,
        name: null,
        birthday: null,
        birth_year: null,
        phone_number: null,
    },
    profile: {
        birthday: null,
        birth_year: null,
        gender: '',
        id: null,
        phone_number: '',
        name: '',
        username: '',
        type: '',
        last_login: null,
        first_address: null,
        is_company_user: false
    },
    snsSignupInfo: {
        type: null,
        // sns_id: null,
        username: null,
        gender: null,
        name: null,
        birthday: null,
        birthyear: null,
        phone_number: null,
        age_range: null,
        email: null,
    }
}

const mutations = {
    [_const.SET_IS_INIT]: (state, payload) => {
        state.isInit = payload;
    },
    [_const.SET_LOGIN_ERROR]: (state, payload) => {
        state.loginError = payload;
    },
    [_const.SET_IS_LOGIN]: (state, payload) => {
        state.isLogin = !!payload;
    },
    [_const.SET_AUTH]: (state, payload) => {
        state.auth = payload || {};
    },
    [_const.SET_IMP]: (state, payload) => {
        if (payload) {
            state.imp.imp_uid = payload.imp_uid ? payload.imp_uid : null;
            state.imp.merchant_uid = payload.merchant_uid ? payload.merchant_uid : null;
            state.imp.gender = payload.gender ? payload.gender : null;
            state.imp.name = payload.name ? payload.name : null;
            state.imp.birthday = payload.birthday ? payload.birthday : null;
            state.imp.birth_year = payload.birth_year ? payload.birth_year : null;
            state.imp.phone_number = payload.phone_number ? payload.phone_number : null;
        }
    },
    [_const.SET_PROFILE]: (state, payload) => {
        state.profile.type = payload ? payload.type : null;
        state.profile.birthday = payload ? payload.birthday : null;
        state.profile.birth_year = payload ? (payload.birth_year || payload.birthyear) : null;
        state.profile.gender = payload ? payload.gender : null;
        state.profile.id = payload ? payload.id : null;
        state.profile.phone_number = payload ? payload.phone_number : null;
        state.profile.name = payload ? payload.name : null;
        state.profile.role = payload ? payload.role : null;
        state.profile.username = payload ? payload.username : null;
        state.profile.email = payload ? payload.email : null;
        state.profile.last_login = payload ? payload.last_login : null;
        state.profile.first_address = payload ? payload.first_address : null;
        state.profile.is_company_user = payload ? payload.is_company_user : false;
    },
    [_const.SET_SNS_SIGNUP]: (state, payload) => {
        state.snsSignupInfo.type = payload.type;

        if (payload.type == 'NAVER') {
            let gender;
            if (payload.gender == 'M') gender = 'M'
            else if (payload.gender == 'F') gender = 'F'
            else if (payload.gender == 'U') gender = 'U'

            // console.log(payload);
            state.snsSignupInfo.sns_id = String(payload.id);
            // state.snsSignupInfo.username = String(payload.id);
            state.snsSignupInfo.email = payload.email;
            state.snsSignupInfo.gender = gender;
            state.snsSignupInfo.name = payload.name;
            state.snsSignupInfo.birthday = payload.birthday;
            state.snsSignupInfo.birthyear = payload.birthyear;
            state.snsSignupInfo.phone_number = payload.mobile;
        } else if (payload.type === 'KAKAO') {
            let gender;
            if (payload.kakao_account.gender == 'male') gender = 'M'
            else if (payload.kakao_account.gender == 'female') gender = 'F'
            else if (payload.kakao_account.gender == 'unknown') gender = 'U'

            let birthday;
            if (payload.kakao_account.birthday) {
                birthday = payload.kakao_account.birthday.slice(0,2) + '-' + payload.kakao_account.birthday.slice(2,4);
            }

            state.snsSignupInfo.sns_id = String(payload.id);
            // state.snsSignupInfo.username = String(payload.id);
            state.snsSignupInfo.email = payload.kakao_account.email;
            state.snsSignupInfo.gender = gender;
            state.snsSignupInfo.name = payload.properties.nickname;
            state.snsSignupInfo.birthday = birthday;
            state.snsSignupInfo.age_range = payload.kakao_account.age_range;
            state.snsSignupInfo.phone_number = payload.kakao_account.phone_number;
        } else if (payload.type === 'GOOGLE') {
            state.snsSignupInfo.sns_id = String(payload.id);
            state.snsSignupInfo.email = payload.email;
            state.snsSignupInfo.name = payload.name;
        } else if (payload.type === 'APPLE') {
            state.snsSignupInfo.sns_id = String(payload.id);
            state.snsSignupInfo.email = payload.email;
        }
    },
}

const getters = {};

const actions = {
    async loginByID({ commit }, { username, password }) {
        try {
            const res = await authApi.login('ID', { username, password });

            if (res.data && res.data.access_token) {
                await commit(_const.SET_IS_INIT, true);
                commit(_const.SET_AUTH, res.data);
                commit(_const.SET_IS_LOGIN, true);

                const userRes = await userApi.getProfile();

                commit(_const.SET_PROFILE, userRes.data);

                return true
            }
        } catch (err) {
            commit(_const.SET_IS_LOGIN, false);
            // console.log(err.response.data.message);
            commit(_const.SET_LOGIN_ERROR, err.response.data.message);
        }
    },

    async loginBySNS({ commit }, { type, sns_id }) {
        try {
            // debugger
            const res = await authApi.loginForSns(type, sns_id);

            commit(_const.SET_AUTH, res.data);
            commit(_const.SET_IS_LOGIN, true);

            const userRes = await userApi.getProfile();

            commit(_const.SET_PROFILE, userRes.data);

            return true
        } catch (err) {
            commit(_const.SET_IS_LOGIN, false);
            console.log(err)
            commit(_const.SET_LOGIN_ERROR, err.message)
        }
    },

    async signupSNS({ commit }, obj) {
        try {
            const data = await userApi.signupSns(obj.type, obj.data);

            // console.log(data);
            if (data && data.access_token) {
                commit(_const.SET_AUTH, data);
                commit(_const.SET_IS_LOGIN, true);

                const userRes = await userApi.getProfile();

                commit(_const.SET_PROFILE, userRes.data);
            }

            return data;
        } catch (err) {
            commit(_const.SET_IS_LOGIN, false);
            commit(_const.SET_LOGIN_ERROR, err.message)

            alert(err.response.data.message);
            return false;
        }
    },

    async loginNaver({ commit }, { code, state }) {
        try {
            const data = await authApi.loginForNaver(code, state);

            if (data && data.access_token) {
                await commit(_const.SET_IS_INIT, true);
                commit(_const.SET_AUTH, data);
                commit(_const.SET_IS_LOGIN, true);

                const userRes = await userApi.getProfile();

                commit(_const.SET_PROFILE, userRes.data);
            } else if (data && data.id) {
                data.type = 'NAVER'
                commit(_const.SET_SNS_SIGNUP, data);
            }

            return data;
        } catch (err) {
            commit(_const.SET_IS_LOGIN, false);
            commit(_const.SET_LOGIN_ERROR, err.message);
        }
    },

    async loginKakao({ commit }, { code }) {
        try {
            const data = await authApi.loginForSnsByCode('kakao', code);

            if (data && data.access_token) {
                await commit(_const.SET_IS_INIT, true);
                commit(_const.SET_AUTH, data);
                commit(_const.SET_IS_LOGIN, true);

                const userRes = await userApi.getProfile();

                commit(_const.SET_PROFILE, userRes.data);
            } else if (data && data.id) {
                data.type = 'KAKAO'
                commit(_const.SET_SNS_SIGNUP, data);
            }

            return data;
        } catch (err) {
            commit(_const.SET_IS_LOGIN, false);
            commit(_const.SET_LOGIN_ERROR, err.message);
        }
    },

    async loginGoogle({ commit }, { code }) {
        try {
            const data = await authApi.loginForSnsByCode('google', code);

            if (data && data.access_token) {
                await commit(_const.SET_IS_INIT, true);
                commit(_const.SET_AUTH, data);
                commit(_const.SET_IS_LOGIN, true);

                const userRes = await userApi.getProfile();

                commit(_const.SET_PROFILE, userRes.data);
            } else if (data && data.id) {
                data.type = 'GOOGLE'
                commit(_const.SET_SNS_SIGNUP, data);
            }

            return data;
        } catch (err) {
            commit(_const.SET_IS_LOGIN, false);
            commit(_const.SET_LOGIN_ERROR, err.message);
        }
    },


    async loginApple({ commit }, { code, id_token, name, sns_id }) {
        try {
            const data = await authApi.loginForSnsByCode('apple', code, id_token, name, sns_id);

            // console.log(data);
            if (data && data.access_token) {
                await commit(_const.SET_IS_INIT, true);
                commit(_const.SET_AUTH, data);
                commit(_const.SET_IS_LOGIN, true);

                const userRes = await userApi.getProfile();

                commit(_const.SET_PROFILE, userRes.data);
            } else if (data && data.id) {
                data.type = 'APPLE'
                commit(_const.SET_SNS_SIGNUP, data);
            }

            return data;
        } catch (err) {
            commit(_const.SET_IS_LOGIN, false);
            commit(_const.SET_LOGIN_ERROR, err.message);
        }
    },

    async logout({ commit }) {
        try {
            // console.log('auth: logout');
            // await authApi.logout();
            // console.log('==========2===========')

            commit(_const.SET_AUTH, {});
            commit(_const.SET_IS_LOGIN, false);
            commit(_const.SET_PROFILE, null);

            // console.log('==========3===========')
            localStorage.removeItem(_const.TOKEN);
            localStorage.removeItem(_const.REFRESH_TOKEN);

            return true
        } catch (err) {
            console.log(err)
            commit(_const.SET_IS_LOGIN, false);
            commit(_const.SET_LOGIN_ERROR, err.message)
        }
    },

    async init({ commit }) {
        let token = localStorage.getItem(_const.TOKEN);
        let refreshToken = localStorage.getItem(_const.REFRESH_TOKEN);

        // console.log(`token: ${token}`);
        // console.log(`refreshToken: ${refreshToken}`);

        if (token) {
            await commit(_const.SET_IS_INIT, true);
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            api.defaults.headers.common['refresh-token'] = `${refreshToken}`;

            const { access_token, refresh_token } = await userApi.refreshReset();

            localStorage.setItem(_const.TOKEN, access_token);
            localStorage.setItem(_const.REFRESH_TOKEN, refresh_token);

            api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
            api.defaults.headers.common['refresh-token'] = `${refresh_token}`;

            const userRes = await userApi.getProfile();

            if (userRes) {
                await commit(_const.SET_PROFILE, userRes.data);

                await commit(_const.SET_IS_LOGIN, true);
                await commit(_const.SET_AUTH, {
                    access_token: token,
                    refresh_token: refreshToken
                });
            }
        }
    }
}

export default {
    state,
    mutations,
    getters,
    actions
}
