import { createApp } from 'vue'
import dayjs from 'dayjs'
import App from './App.vue'
import router from './router'
import store from './store'
import helper from './helper'
import VueLoading from 'vue-loading-overlay';
import VueLazyload from 'vue-lazyload'
import VueWriter from "vue-writer";
import Toaster from "@meforma/vue-toaster";
import 'vue-loading-overlay/dist/vue-loading.css';
import { SplashScreen } from '@capacitor/splash-screen';
import { App as CapacitorApp } from '@capacitor/app'

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

// import vue3GoogleLogin from 'vue3-google-login'

if (window && window.location && window.location.host === 'www.mrbob.kr') {
    window.location.href = 'https://mrbob.kr';
}


const app = createApp(App);

app.config.globalProperties.$helper = helper;
app.config.globalProperties.dayjs = dayjs;

// app.use(FunctionalCalendar, {
//     dayNames: ['M', 'T', 'W', 'T', 'F', 'S', 'S']
// });

// const gAuthOptions = {
//     clientId: _const.GOOGLE_ID,
// };

app
    .use(VueLoading, {
        color: '#ff3d00',
        width: 60,
        height: 60
    })
    .use(VueLazyload, {
        preLoad: 1.3
    })
    .use(VueWriter)
    // .use(vue3GoogleLogin, gAuthOptions)
    .use(Toaster)
    .use(store)
    .use(router);

/**
 * 초기화 함수
 * 애플리케이션 초기화 작업을 수행합니다.
 * 예: Vuex 상태 초기화, 라우팅 초기화 등
 */
const initializeApp = () => {
    // console.log(' >>> APP INITIALIZED');
    // 초기화 작업 수행
    // 예: Vuex 상태 초기화, 라우팅 초기화 등
};


if (process.env.NODE_ENV !== 'local') {
    // console.info('[production] sentry init');
    Sentry.init({
        app,
        dsn: "https://8f64aebc81f743c3808a4af563d74c5e@o4504018895175680.ingest.sentry.io/4504018896420864",
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["localhost", "mrbob.kr", "www.mrbob.kr", /^\//],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

// Back button event handler
CapacitorApp.addListener('backButton', ({ canGoBack }) => {
    if (canGoBack) {
        window.history.back()
    } else {
        CapacitorApp.exitApp()
    }
})

/**
 * DOMContentLoaded 이벤트 리스너
 * DOM이 완전히 로드된 후에 실행되는 코드 블록입니다.
 * 초기화 작업을 수행하고, 스플래시 스크린을 숨깁니다.
 */
document.addEventListener('DOMContentLoaded', () => {
    // 애플리케이션 초기화 함수 호출
    initializeApp();
    // 스플래시 스크린을 숨깁니다.
    SplashScreen.hide();
});

// Vue 애플리케이션을 #app 엘
app.mount('#app');
