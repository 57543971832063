import _const from '../const';
import { Capacitor } from "@capacitor/core"

const state = {
    isSideMenuShow: false,
    isAppMenuShow: Capacitor.isNativePlatform() || window.navigator.userAgent.indexOf("bob_app") > -1,
}

const mutations = {
    [_const.SET_MOBILE_SIDE_MENU]: (state, payload) => {
        state.isSideMenuShow = payload;
    }
}

const getters = {
    // getList() {
    //     return state.notifications;
    // }
    isAppMenuShow(state) { return state.isAppMenuShow; },
}

const actions = {
    toggleSideMenu({ commit }, isShow) {
        commit(_const.SET_MOBILE_SIDE_MENU, isShow);
    }
    //
    // deleteListItem({ commit }, idx) {
    //     commit()
    // }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
