import _const from "./store/const";
import dayjs from "dayjs";

export default {
    loadIamportScript(cb) {
        if (window.isLoadedImport) return cb && cb();

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://cdn.iamport.kr/v1/iamport.js';
        script.onload = () => {
            window.isLoadedImport = true;
            cb && cb();
        }

        document.head.appendChild(script);
    },

    getEndDate(startDate, period) {
        return dayjs(startDate).add(period, 'month').subtract(1, 'day').format('YYYY-MM-DD');
    },

    getUrl(url) {
        var result = "http://127.0.0.1:8080" + url;

        if (window.location.host === "dev.mrbob.kr") {
            result = "https://dev.mrbob.kr" + url;
        } else if (window.location.host === "stg.mrbob.kr") {
            result = "https://stg.mrbob.kr" + url;
        } else if (window.location.host === "mrbob.kr") {
            result = "https://mrbob.kr" + url;
        }

        return result;
    },

    isDev() {
        return window.location.host === 'localhost:8080' || window.location.host === '127.0.0.1:8080' || window.location.host === 'dev.mrbob.kr';
    },

    getBoxCntBySpec(spec) {
        let box = Math.ceil(((spec.width * spec.height * spec.depth) / (118 * 1000)) * 10) / 10;

        if (spec.cnt > 1) box = (box * spec.cnt).toFixed(1);

        return box;
    },

    getMoneyStr(number) {
        if (number) {
            return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
            return 0
        }
    },

    getStr(key, data) {
        return _const[key][data] || ''
    },

    cloneObject(obj) {
        let clone = {};
        for (let key in obj) {
            if (typeof obj[key] == "object" && obj[key] != null) {
                clone[key] = this.cloneObject(obj[key]);
            } else {
                clone[key] = obj[key];
            }
        }

        return clone;
    },

    validatePasswd(passwd) {
        let check1 = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,20}$/.test(passwd);   //영문,숫자
        let check2 = /^(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,20}$/.test(passwd);  //영문,특수문자
        let check3 = /^(?=.*[^a-zA-Z0-9])(?=.*[0-9]).{8,20}$/.test(passwd);  //특수문자, 숫자

        return check1 || check2 || check3;
    },

    splitPhoneNumber(number) {
        let splited
        if (number) {
            splited = number.match(/^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/);
        } else {
            return {
                number1: '',
                number2: '',
                number3: ''
            };
        }

        return {
            number1: splited[1],
            number2: splited[2],
            number3: splited[3]
        }
    },

    sendGA(event, params) {
        // window.gtag('event', event, params);
        console.log(params);
    },

    getFloorTxt(floor) {
        if (!floor) return '1';

        if (floor < 0) {
            return `지하 ${Math.abs(floor)}`;
        }

        return floor;
    }
};
