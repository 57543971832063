<template>
  <div v-if="visible" class="backdrop">
    <div class="container">

      <div>
        <span class="description">서버와의 통신이 원활하지 않습니다.</span>
        <br/>
        <span class="description">인터넷 연결 상태를 확인해 주세요.</span>
      </div>
      <div class='alert_footer'>
        <button @click="handleExit" class='cancle_btn'>종료하기</button>
        <button @click="handleRetry" class='retry_btn'>다시 시도</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  name: "NetworkAlert",
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      if (this.visible) {
        window.location.reload()
        this.visible = false;
      }
    },
    handleExit() {
      this.hide();
      this.$emit('exit');
    },
    handleRetry() {
      this.hide();
      this.$emit('retry');
    }
  }
};
</script>

<style scoped lang="scss">
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  text-align: center;
  z-index: 99999px;

  .description{
    line-height: 150%;
  }

}

.alert_footer {
  margin-top: 3rem;
  display: flex;
  column-gap: 1rem;
  justify-content: center;
  button{
    border-radius: 10px;
    outline: none;
    border: none;
    padding: 1rem 1.5rem;
    width: 100%;
  }
  .cancle_btn {
    color: white;
    background-color:#747474;
  }
  .retry_btn {
    color: white;
    background-color: $sub-color
  }
}

</style>

